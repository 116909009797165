<template>
  <div class="main-body">
    <div class="card-item">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="order-attention">
          <p>小程序开发者设置</p>
        </div>

        <a-form-item label="小程序名称">
          <a-input v-model:value="formState.wxapp_name" placeholder="请输入小程序名称" />
        </a-form-item>
        <a-form-item label="原始ID">
          <a-input v-model:value="formState.wxapp_original" placeholder="请输入原始ID" />
        </a-form-item>
        <a-form-item label="小程序二维码">
          <CsImage v-model:imgOne="formState.qrcode" />
        </a-form-item>
        <div class="order-attention">
          <p>开发者设置</p>
        </div>
        <a-form-item label="APPID">
          <a-input v-model:value="formState.app_id" placeholder="请输入APPID" />
        </a-form-item>
        <a-form-item label="APP密钥">
          <a-input v-model:value="formState.app_secret" placeholder="请输入APP密钥" />
        </a-form-item>
        <a-form-item label="业务域名校验文件" extra="仅支持上传TXT格式的文件">
          <UpFile v-model:imgOne="formState.check_file" />
        </a-form-item>

        <div class="order-attention">
          <p>服务器配置信息</p>
        </div>
        <a-form-item label="request合法域名">
          <a-input v-model:value="formState.url_request" placeholder="请输入request合法域名" />
        </a-form-item>
        <a-form-item label="socket合法域名">
          <a-input v-model:value="formState.url_socket" placeholder="请输入socket合法域名" />
        </a-form-item>
        <a-form-item label="uploadFile合法域名">
          <a-input v-model:value="formState.url_upload" placeholder="请输入uploadFile合法域名" />
        </a-form-item>
        <a-form-item label="downloadFile合法域">
          <a-input v-model:value="formState.url_download" placeholder="请输入downloadFile合法域" />
        </a-form-item>
        <div class="order-attention">
          <p>消息推送设置</p>
        </div>
        <a-form-item label="URL(服务器地址)" extra="必须以http://或https://开头，分别支持80端口和443端口。">
          <a-input v-model:value="formState.callback_url" placeholder="请输入服务器地址" />
        </a-form-item>
        <a-form-item label="Token(令牌)" extra="Token必须为英文或数字，长度为3-32字符。如不填写则默认为“TOKEN”。">
          <a-input v-model:value="formState.token" placeholder="请输入Token" />
        </a-form-item>
        <a-form-item label="EncodingAESKey" extra="消息加密密钥由43位字符组成，字符范围为A-Z,a-z,0-9">
          <a-input v-model:value="formState.encoding_aes_key" placeholder="请输入EncodingAESKey" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 6, offset: 2 }">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import CsImage from "@/components/UpImage";
import UpFile from "@/components/UpFile";
import { client_save_config, client_get_config } from "@/views/client/api";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
export default defineComponent({
  components: {
    CsImage,
    UpFile,
  },
  setup() {
    //获取当前应用ID
    const route = useRoute();
    const appid = route.query.appid;

    const formState = reactive({
      appid: appid,
      wxapp_name: "",
      wxapp_original: "",
      qrcode: "",
      app_id: "",
      app_secret: "",
      check_file: "",
      url_request: "",
      url_socket: "",
      url_upload: "",
      url_download: "",
      callback_url: "",
      token: "",
      encoding_aes_key: "",
    });

    const onSubmit = () => {
      client_save_config(formState).then((res) => {
        if (res.status == 200) {
          message.success(res.message);
          getInfo();
        }
      });
    };
    const getInfo = () => {
      client_get_config({ appid: appid }).then((res) => {
        if (res.status == 200) {
          formState.wxapp_name = res.data.setting.wxapp_name
            ? res.data.setting.wxapp_name
            : "";
          formState.wxapp_original = res.data.setting.wxapp_original
            ? res.data.setting.wxapp_original
            : "";
          formState.qrcode = res.data.setting.qrcode
            ? res.data.setting.qrcode
            : "";
          formState.app_id = res.data.setting.app_id
            ? res.data.setting.app_id
            : "";
          formState.app_secret = res.data.setting.app_secret
            ? res.data.setting.app_secret
            : "";
          formState.check_file = res.data.setting.check_file
            ? res.data.setting.check_file
            : "";
          formState.token = res.data.setting.token
            ? res.data.setting.token
            : "";
          formState.url_request = res.data.setting.url_request
            ? res.data.setting.url_request
            : "";
          formState.url_socket = res.data.setting.url_socket
            ? res.data.setting.url_socket
            : "";
          formState.url_upload = res.data.setting.url_upload
            ? res.data.setting.url_upload
            : "";
          formState.url_download = res.data.setting.url_upload
            ? res.data.setting.url_download
            : "";
          formState.callback_url = res.data.setting.callback_url
            ? res.data.setting.callback_url
            : "";
          formState.encoding_aes_key = res.data.setting.encoding_aes_key
            ? res.data.setting.encoding_aes_key
            : "";
        }
      });
    };
    onMounted(() => {
      getInfo();
    });
    return {
      formState,
      onSubmit,
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 6,
      },
    };
  },
});
</script>
<style scoped lang="less">
.card-item {
  margin-bottom: 50px;
  .action-body {
    display: flex;
    flex-wrap: wrap;
    .action-item {
      width: 20%;
      .action-item-btn {
        display: flex;
        background-color: #f0f2f5;
        margin: 20px;
        padding: 10px;
        border-radius: 3px;
        cursor: pointer;
        .action-icon {
          font-size: 36px;
          margin: 6px 10px;
          color: #52c41a;
        }
        .title {
          font-size: 14px;
        }
        .desc {
          color: #999999;
        }
      }
    }
  }
  .weixin-url {
    margin: 20px 0;
  }
  .action-time {
    margin-left: 20px;
  }
}
</style>